
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProductsM } from '../modules'
import { storesApi } from '../../axios'

type InitialState = {
    loading: boolean
    error: null | string | undefined
    product: ProductsM[]
}

const initialState: InitialState = {
    error: null,
    loading: false,
    product: [],
}


export const fetchByFilterCategory = createAsyncThunk<ProductsM[], number, { rejectValue: string }>(
    'filtersCategories/fetchByFilterCategory', async (id, { rejectWithValue }) => {
        const res = await storesApi.getFilterCategories(id)
        // console.log(res)
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.products
    })


const FiltersCategoriesSlice = createSlice({
    name: 'filtersCategories',
    initialState,
    reducers: {

    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByFilterCategory.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByFilterCategory.fulfilled, (state, action) => {
            state.product = action.payload
            state.loading = false
        })

        addCase(fetchByFilterCategory.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'Упс что-то пошло не так попробуйте снова!'
            } else {
                state.error = action.payload
            }
        })
    },
})

export default FiltersCategoriesSlice.reducer
