import React, { FC, useEffect } from 'react';
import s from './PartnerSlider.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByHomeInfo } from '../../../store/slice/allInfoSlice';
import volvo from '../../../assets/Home/Reel.png'

const PartnerSlider: FC = () => {
    const { homeinfo } = useAppSelector(state => state.allInfo)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByHomeInfo())
    }, [dispatch])
    return (

        <div className={s.PsCon}>
            <div className={s.volvo}>
                <img src={volvo} alt="" />
            </div>
            <div
                style={{
                    backgroundImage: ` url(${homeinfo?.main_page_second_banner_image.startsWith('http') ?
                        `https${homeinfo?.main_page_second_banner_image.slice(4)}` : homeinfo?.main_page_second_banner_image
                        })`

                }}
                className={s.PartnerSlider}>
                <div className={`container ${s.PartnerSliderBox}`}>
                    <h2 className={s.title}>{homeinfo?.main_page_second_banner_text}</h2>
                </div>
            </div>
        </div>
    );
};

export default PartnerSlider;