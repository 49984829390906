import React, { FC, useEffect } from 'react';
import s from './EquipmentStock.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByProductInStock } from '../../../store/slice/allInfoSlice';
import { Link } from 'react-router-dom';

const EquipmentStock: FC = () => {
    const { proInStock } = useAppSelector(state => state.allInfo)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByProductInStock())
    }, [dispatch])

    return (
        <>
            {
                proInStock.length > 0 &&
                <div className={s.EquipmentStock}>
                    <div className={s.titleBox}>
                        <h2 className={s.title}>Техника в наличии</h2>
                    </div>
                    <div className={`container ${s.cardBox}`}>
                        {
                            proInStock?.length > 0 &&
                            proInStock.slice(0, 2).map(el => (
                                <Link to={`/detailed_view?id=${el.id}`} className={s.card} key={el.id}>
                                    <div className={s.content}>
                                        {/* <h3 className={s.subtitle}>{el?.sub_category?.name}</h3> */}
                                        <h2 className={s.title}>{el?.name}</h2>
                                    </div>
                                    <div className={s.img}>
                                        <img src={el?.image.startsWith('http') ? `https${el?.image.slice(4)}` : el?.image} alt="" />
                                    </div>
                                    <p className={s.paragraf}>{el?.description.length > 40 ? el?.description.slice(0, 40) + '...' : el?.description}</p>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default EquipmentStock;