import React, { FC, useEffect } from 'react';
import s from './AboutCompany.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAddAboutCompanyInfo } from '../../store/slice/allInfoSlice';
import Partner from '../../components/Section/Partner/Partner';
import Coments from '../../components/Section/Coments/Coments';
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/Loading/Loading';
import Contacts from '../../components/Contacts/Contacts';

const AboutCompany: FC = () => {
  const { aboutCompanyInfo, loading } = useAppSelector(state => state.allInfo)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchByAddAboutCompanyInfo())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  return (
    <>
      <div className={s.AboutCompany} >
        <Helmet>
          <meta property="og:title" content={`О компании | Anto`} />
          <meta name="twitter:title" content={`О компании | Anto`} />
          <link rel="canonical" href={`https://anto.kg/about-company`} />
          <title>
            О компании | Anto
          </title>
        </Helmet>
        <div className={s.image_about_company}>
          <h2 className={s.description}>{aboutCompanyInfo?.about_page_banner_text}</h2>
          <img src={aboutCompanyInfo?.about_page_image.startsWith('http') ? `https${aboutCompanyInfo?.about_page_image.slice(4)}` : aboutCompanyInfo?.about_page_image} alt="Kyrgyzstan" />
        </div>
        <div className={`container ${s.text_in}`}>
          <p>{aboutCompanyInfo?.about_us} </p>
        </div>
        <div className={s.about_company_video}>
          <iframe
            src={aboutCompanyInfo?.about_page_video.slice(0, 24) + 'embed/' + aboutCompanyInfo?.about_page_video.slice(32)}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </div>
        <div className={s.official_rep}>
          <h2>Мы официальные представители:</h2>
          <Partner />
        </div>
        <div className={s.reviews}>
          <h2>Наши партнеры</h2>
          <Coments />
        </div>
        <Contacts />
      </div>
      {loading && <Loading />}
    </>
  );
};

export default AboutCompany;