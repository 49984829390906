import React, { FC, useEffect, useRef } from 'react';
import s from './BurgerMenu.module.scss'
import { MdClose } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByAllInfo } from '../../../store/slice/allInfoSlice';
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

interface BurgerMenuProps {
    setIsActiveBurger: (e: boolean) => void
    isActiveBurger: boolean
}
const BurgerMenu: FC<BurgerMenuProps> = ({ isActiveBurger, setIsActiveBurger }) => {
    const { allInfo } = useAppSelector(state => state.allInfo)
    const dispatch = useAppDispatch()
    const blockRef = useRef<HTMLDivElement | null>(null)
    const hadleVisib = () => {
        setIsActiveBurger(false)
    }

    const toggleBlock = (e: MouseEvent) => {
        const { target } = e
        if (blockRef.current && !blockRef.current.contains(target as Node)
            && target instanceof HTMLElement && !target.className.includes('BurgerMenu')
            && !target.className.includes('BurgerAni') && !target.className.includes('menu_item')) {
            setIsActiveBurger(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', toggleBlock)

        return () => document.removeEventListener('mousedown', toggleBlock)
    }, [isActiveBurger])

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && hadleVisib()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (isActiveBurger) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => hadleVisib();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!isActiveBurger) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    useEffect(() => {
        dispatch(fetchByAllInfo())
    }, [dispatch])
    return (
        <div ref={blockRef} onClick={(e) => e.stopPropagation()} className={isActiveBurger ? s.BurgerMenu : ` ${s.BurgerMenu} ${s.BurgerAni}`}>
            <div className={s.in_burger_menu}>
                <MdClose className={s.close_icon} onClick={hadleVisib} />
                <ul>
                    <li onClick={hadleVisib} className={s.menu_item}><NavLink to={'/catalog'} className={({ isActive }) =>
                        isActive ? `${s.menu_link} ${s.active_nav}` : s.menu_link
                    }>Каталог</NavLink></li>
                    <li onClick={hadleVisib} className={s.menu_item}><NavLink to={'/about-company'} className={({ isActive }) =>
                        isActive ? `${s.menu_link} ${s.active_nav}` : s.menu_link
                    }>О компании</NavLink></li>
                    <li onClick={hadleVisib} className={s.menu_item}><NavLink to={'/leasing'} className={({ isActive }) =>
                        isActive ? `${s.menu_link} ${s.active_nav}` : s.menu_link
                    }>Лизинг</NavLink></li>
                    <li onClick={hadleVisib} className={s.menu_item}><ScrollLink onClick={hadleVisib} to="footer" smooth={true} duration={500} className={s.menu_link}>Контакты</ScrollLink></li>
                </ul>
                <a className={s.phoneNumber} target="_blank" rel="noreferrer" href={`tel:${allInfo?.phone_number}`}>{allInfo?.phone_number}</a>
            </div>
        </div >
    );
};

export default BurgerMenu;