import React, { FC } from 'react';
import s from './CatalogCard.module.scss'
import { Link } from 'react-router-dom';
import { CategoriesSubM } from '../../../store/modules';
import { useAppDispatch } from '../../../store/hooks/hooks';
import { fetchByFilterCategory } from '../../../store/slice/FiltersCategoriesSlice';
import auto from '../../../assets/Subdirectory/9162.jpg'
interface subCategoriesProps {
    subCategories: CategoriesSubM,
    name: string,
    image: string,
    id: number,
}

const CatalogCard: FC<subCategoriesProps> = ({ name, image, id }) => {
    const dispatch = useAppDispatch()
    const filterOnclick = () => {
        id &&
            dispatch(fetchByFilterCategory(id))
    }
    return (
        <Link onClick={filterOnclick} to={`/subdirectory?id=${id}`} className={s.card}>
            <div key={id} className={s.cardImg}>
                <img src={image === null ? auto : image.startsWith('http') ? `https${image.slice(4)}` : image} alt="" />
            </div>
            <p>{name}</p>
        </Link>
    );
};

export default CatalogCard;