import React, { FC } from 'react';
import s from './Advantages.module.scss'
import adv1 from '../../../assets/Home/adv1.png'
import adv2 from '../../../assets/Home/adv2.png'
import adv3 from '../../../assets/Home/adv3.png'
import adv4 from '../../../assets/Home/adv4.png'

const Advantages: FC = () => {
    return (
        <div className={s.Advantages}>
            <div className={s.titleBox}>
                <h2 className={s.title}>Мы предлагаем Вам</h2>
            </div>
            <div className={`container ${s.AdvantagesCard}`}>
                <div className={s.card}>
                    <div className={s.img}>
                        <img src={adv1} alt="" />
                    </div>
                    <h3 className={s.subtitle}>Спецтехника №1</h3>
                    <p className={s.parafraf}> Спецтехнику от лучших производителей Китая, входящие в топ  50 мировых производителей.</p>
                </div>
                <span className={s.line}></span>
                <div className={s.card}>
                    <div className={s.img}>
                        <img src={adv3} alt="" />
                    </div>
                    <h3 className={s.subtitle}>Запчасти</h3>
                    <p className={s.parafraf}>Любые запчасти и комплектующие к нашей технике, напрямую с заводов производителей</p>
                </div>
                <span className={s.line}></span>
                <div className={s.card}>
                    <div className={s.img}>
                        <img src={adv4} alt="" />
                    </div>
                    <h3 className={s.subtitle}>Сервис</h3>
                    <p className={s.parafraf}> Проведем диагностику и подберем необходимые запчасти, при необходимости организуем выезд на место.</p>
                </div>
                <span className={s.line}></span>
                <div className={s.card}>
                    <div className={s.img}>
                        <img src={adv2} alt="" />
                    </div>
                    <h3 className={s.subtitle}>Гарантия</h3>
                    <p className={s.parafraf}>При приобретении нашей техники, Вы получаете официальную гарантию от завода производителя.</p>
                </div>
            </div>
        </div>
    );
};

export default Advantages;