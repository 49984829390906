import axios from 'axios'
import { pathLink } from '../reused'

const instance = axios.create({
  baseURL: pathLink,
  headers: {
    'Content-Type': 'application/json',
  },
})


export const storesApi = {
  getAllInfo() {
    return instance.get('/api/information/information/')
  },
  getAllCategories() {
    return instance.get('/api/categories/categories/')
  },
  getAllSubCategories() {
    return instance.get('/api/categories/subcategories/')
  },
  getFilterCategories(id: number) {
    return instance.get(`/api/categories/subcategories/${id}/products/`)
  },
  getProductId(id: number) {
    return instance.get(`/api/products/products/${id}/`)
  },
  getAboutCompanyInfo() {
    return instance.get('/api/information/about-page-information/')
  },
  getLeasingInfo() {
    return instance.get('/api/information/leasing-info/')
  },
  getHomeInfo() {
    return instance.get('/api/information/main-page-informations/')
  },
  getComment() {
    return instance.get('/api/comments/comments/')
  },
  getProductsinstock() {
    return instance.get('/api/products/products-in-stock/')
  },
  getPeopleChoice() {
    return instance.get('/api/products/people-choice/people-choice/')
  }
}
