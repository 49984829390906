import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProductsM } from '../modules'
import { storesApi } from '../../axios'

type InitialState = {
  loading: boolean
  error: null | string | undefined
  productId: ProductsM | null
}

const initialState: InitialState = {
  error: null,
  loading: false,
  productId: null
}

export const fetchByProductId = createAsyncThunk<ProductsM, number, { rejectValue: string }>(
  'productId/fetchByProductId', async (id, { rejectWithValue }) => {
    const res = await storesApi.getProductId(id)
    // console.log(res)
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data as ProductsM
  })


const productSlice = createSlice({
  name: 'productId',
  initialState,
  reducers: {

  },
  extraReducers: ({ addCase }) => {
    addCase(fetchByProductId.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByProductId.fulfilled, (state, action) => {
      state.productId = action.payload
      state.loading = false
    })

    addCase(fetchByProductId.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
  },
})

export default productSlice.reducer
