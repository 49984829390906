import React, { FC, useState } from 'react';
import './Coments.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Keyboard } from 'swiper/modules';
import { useAppSelector } from '../../../store/hooks/hooks';
import VideoComments from './VideoComments/VideoComments';

const Coments: FC = () => {
  const { comments } = useAppSelector(state => state.allInfo)
  const [linkVideo, setLinkVideo] = useState('')
  const [videoComment, setVideoComment] = useState(false)
  const handleVideo = (id: string) => {
    comments.filter(el => el.id === id && setLinkVideo(el.video_link))
    setVideoComment(true)
  }
  return (
    <>
      <div className='coments_swiper'>
        <Swiper
          pagination={{
            dynamicBullets: true,
            clickable: true
          }}
          keyboard={{
            enabled: true
          }}
          modules={[Pagination, Keyboard]}
          slidesPerView={window.screen.width <= 767 ? 1 : window.screen.width <= 1024 ? 2 : 3}
          spaceBetween={30}
          className="mySwiper"
        >
          {
            comments.length > 0 && comments.map(el => (
              <SwiperSlide key={el.id}>
                <div className='card_in_comment' >
                  <div className='info_in_commit'>
                    <img src={el.avatar.startsWith('http') ? `https${el.avatar.slice(4)}` : el.avatar} alt={el.title} />
                    <div className="in_card_swiper">
                      <h2>{el.title}</h2>
                      <span>{el.description}</span>
                    </div>
                  </div>
                  <button onClick={() => handleVideo(el.id)} className='button_in_video'>Смотреть видео</button>
                </div>
              </SwiperSlide>))
          }
        </Swiper>
      </div >
      {videoComment && <VideoComments linkInVideo={linkVideo} videoComment={videoComment} setVideoComment={setVideoComment} />
      }
    </>
  );
};

export default Coments;
