import React, { FC, useEffect } from 'react';
import s from './VideoComments.module.scss'

interface iVideo {
  linkInVideo: string
  videoComment: boolean
  setVideoComment: (Value: boolean) => void
}
const VideoComments: FC<iVideo> = ({ linkInVideo, setVideoComment, videoComment }) => {

  useEffect(() => {
    // При рождении убрать скрол
    document.body.style.overflow = 'hidden'
    // При нажатии на ESC закрыть модальное окно
    document.addEventListener('keydown', (e) => {
      e.code === 'Escape' && setVideoComment(false)
    })
    // При рождении навесит другое событие на кнопку назад у браузера
    if (videoComment) {
      window.history.pushState(null, '', window.location.href)
      window.onpopstate = () => setVideoComment(false);
    }
    return () => {
      // При закрытии  модального окна вернуть скролл
      document.body.style.overflow = 'auto'
      // При закрытии убрать действия с кнопки ESC
      document.removeEventListener('keydown', () => { })
      // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
      if (!videoComment) window.history.back();
      window.onpopstate = () => { };
    }
  }, [])


  return (
    <div className={s.Confirmation} onClick={() => setVideoComment(false)}>
      <div className={s.box} onClick={e => e.stopPropagation()}>
        <iframe
          className={s.video_box}
          src={linkInVideo.slice(0, 24) + 'embed/' + linkInVideo.slice(32) + '?autoplay=1'}
          title='YouTube video player'
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          frameBorder="0"
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        ></iframe>
      </div>
    </div>
  );
};

export default VideoComments;
