import React, { FC, useEffect } from 'react';
import s from './Catalog.module.scss'
import CatalogCard from './CatalogCard/CatalogCard';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAllSubCategory } from '../../store/slice/subCategoriesSlice';
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/Loading/Loading';
import Contacts from '../../components/Contacts/Contacts';

const Catalog: FC = () => {
  const dispatch = useAppDispatch()
  const { subCategories, loading } = useAppSelector(state => state.subCategoriesTS)
  useEffect(() => {
    dispatch(fetchByAllSubCategory())
  }, [dispatch])
  // console.log(subCategories);

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11298776770';
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-11298776770');
    `;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);
  return (
    <>
      <div className={`container ${s.catalog}`}>
        <Helmet>
          <meta property="og:title" content={`Каталог | Anto`} />
          <meta name="twitter:title" content={`Каталог | Anto`} />
          <link rel="canonical" href={`https://anto.kg/catalog`} />
          <title>
            Каталог | Anto
          </title>
        </Helmet>
        <h2 className={s.categoriesH}>Каталог</h2>
        <div className={s.cardCategories}>
          {
            subCategories.length > 0 && subCategories.map(el => <CatalogCard name={el.name} image={el.image} id={el.id} key={el.id} subCategories={el} />)
          }
        </div>
      </div>
      <Contacts />
      {loading && <Loading />}
    </>
  );
};

export default Catalog;