import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storesApi } from '../../axios'
import { HomeInfo, IAllInfo, IComments, IInfoAboutCompany, ILeasing, ProductInstock, ProductsM } from '../modules'

type InitialState = {
  loading: boolean
  error: null | string | undefined
  allInfo: IAllInfo | null
  aboutCompanyInfo: IInfoAboutCompany | null
  leasing: ILeasing | null
  homeinfo: HomeInfo | null
  comments: IComments[]
  proInStock: ProductInstock[]
  people_choice: ProductsM[]
}

const initialState: InitialState = {
  error: null,
  loading: false,
  allInfo: null,
  aboutCompanyInfo: null,
  leasing: null,
  homeinfo: null,
  comments: [],
  proInStock: [],
  people_choice: []
}

export const fetchByProductInStock = createAsyncThunk<ProductInstock[], void, { rejectValue: string }>(
  'profile/fetchByProductInStock',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getProductsinstock()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data
  }
)

export const fetchByHomeInfo = createAsyncThunk<HomeInfo, void, { rejectValue: string }>(
  'profile/fetchByHomeInfo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getHomeInfo()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as HomeInfo
  }
)

export const fetchByAllInfo = createAsyncThunk<IAllInfo, void, { rejectValue: string }>(
  'profile/fetchByAllInfo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getAllInfo()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as IAllInfo
  }
)

export const fetchByAddAboutCompanyInfo = createAsyncThunk<IInfoAboutCompany, void, { rejectValue: string }>(
  'profile/fetchByAddAboutCompanyInfo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getAboutCompanyInfo()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as IInfoAboutCompany
  }
)

export const fetchByAddLeasingInfo = createAsyncThunk<ILeasing, void, { rejectValue: string }>(
  'profile/fetchByAddLeasingInfo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getLeasingInfo()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as ILeasing
  }
)

export const fetchByComments = createAsyncThunk<IComments[], void, { rejectValue: string }>(
  'profile/fetchByComments',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getComment()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data
  }
)

export const fetchByPeopleChoice = createAsyncThunk<ProductsM[], void, { rejectValue: string }>(
  'profile/fetchByPeopleChoice',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getPeopleChoice()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data
  }
)

const allInfoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {

  },
  extraReducers: ({ addCase }) => {

    addCase(fetchByProductInStock.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByProductInStock.fulfilled, (state, action) => {
      state.proInStock = action.payload
      state.loading = false
    })

    addCase(fetchByProductInStock.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // =====

    addCase(fetchByHomeInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByHomeInfo.fulfilled, (state, action) => {
      state.homeinfo = action.payload
      state.loading = false
    })

    addCase(fetchByHomeInfo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })

    // ===============
    addCase(fetchByAllInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByAllInfo.fulfilled, (state, action) => {
      state.allInfo = action.payload
      state.loading = false
    })

    addCase(fetchByAllInfo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
    addCase(fetchByAddAboutCompanyInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByAddAboutCompanyInfo.fulfilled, (state, action) => {
      state.aboutCompanyInfo = action.payload
      state.loading = false
    })

    addCase(fetchByAddAboutCompanyInfo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
    addCase(fetchByAddLeasingInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByAddLeasingInfo.fulfilled, (state, action) => {
      state.leasing = action.payload
      state.loading = false
    })

    addCase(fetchByAddLeasingInfo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
    addCase(fetchByComments.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByComments.fulfilled, (state, action) => {
      state.comments = action.payload
      state.loading = false
    })

    addCase(fetchByComments.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
    addCase(fetchByPeopleChoice.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByPeopleChoice.fulfilled, (state, action) => {
      state.people_choice = action.payload
      state.loading = false
    })

    addCase(fetchByPeopleChoice.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
  },
})
// export const {} = allInfoSlice.actions

export default allInfoSlice.reducer
