import React, { FC, useEffect, useState } from 'react';
import './PeopleChoice.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import { fetchByHomeInfo } from '../../../store/slice/allInfoSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { NavLink } from 'react-router-dom';

const PeopleChoice: FC = () => {
    const { people_choice } = useAppSelector(state => state.allInfo);
    const dispatch = useAppDispatch();

    const [isNavigationEnabled, setIsNavigationEnabled] = useState(true);

    useEffect(() => {
        dispatch(fetchByHomeInfo());
    }, [dispatch]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 767) {
                setIsNavigationEnabled(false);
            } else {
                setIsNavigationEnabled(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call it initially to set the state based on initial window size

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className='PeopleChoiceBox container'>
            <div className='PCtitleBox'>
                <h2 className='PCtitle'>Выбор народа!</h2>
            </div>
            <div className='swiper_in_home'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    keyboard={{
                        enabled: true,
                    }}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    navigation={isNavigationEnabled}
                    modules={[Keyboard, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {
                        people_choice && people_choice?.length > 0 &&
                        people_choice?.map(el => (
                            <SwiperSlide key={el.id}>
                                <div className='PCcard'>
                                    <div className='PCimg'>
                                        <img src={el?.image.startsWith('http') ? `https${el?.image.slice(4)}` : el?.image} alt="" />
                                    </div>
                                    <NavLink to={`/detailed_view?id=${el.id}`} className='PCsubtitle'>{el?.name}</NavLink>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
};

export default PeopleChoice;
