import React, { FC, useEffect } from 'react';
import s from './Contacts.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAllInfo } from '../../store/slice/allInfoSlice';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const Contacts: FC = () => {

  const { allInfo } = useAppSelector(state => state.allInfo)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchByAllInfo())
  }, [dispatch])

  return (
    <section className={`container ${s.contacts_section}`}>
      <h2 className={s.Contacts}>Контакты</h2>
      <div className={s.image_in}>
        <YMaps>
          <Map
            defaultState={{
              center: [42.831634, 74.584684],
              zoom: 15,
              controls: ["zoomControl", "fullscreenControl"],
            }}
            modules={['control.ZoomControl', 'control.FullscreenControl', 'geoObject.addon.balloon']}
            className={s.maps_card}
          >
            <Placemark
              geometry={[42.831634, 74.584684]}
              properties={{
                balloonContentBody: "This is balloon loaded by the Yandex.Maps API module system",
              }}
            />
          </Map>
        </YMaps>
      </div>
      <div className={s.all_address}>
        <div className={s.address}>
          <a href={`https://2gis.kg/bishkek/search/${encodeURIComponent(`${allInfo?.address}`)}`} target='_blank' rel="noreferrer" className={s.paragraf}>{allInfo?.address}</a>

        </div>
        <div className={s.phone_number}>
          <a target="_blank" rel="noreferrer" href={`tel:${allInfo?.phone_number}`}>{allInfo?.phone_number}</a>
          <span className={s.days_week}>Пн-Пт 09:00-18:00</span>
        </div>
        <div className={s.mail}>
          <a target="_blank" rel="noreferrer" href={`mailto:${allInfo?.email}`}>{allInfo?.email}</a>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
