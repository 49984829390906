import React, { FC, useEffect } from 'react';
import s from './Partner.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByAddLeasingInfo } from '../../../store/slice/allInfoSlice';



const Partner: FC = () => {
    const { allInfo } = useAppSelector(state => state.allInfo)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByAddLeasingInfo())
    }, [dispatch])

    return (
        <div className={s.partnerCon}>
            <div className={s.partnerBox}>
                {allInfo && allInfo?.brand_images.length > 0 &&
                    allInfo?.brand_images.map(el => (
                        <div key={el.id}>
                            <img className={s.img} src={el.image.startsWith('http') ? `https${el.image.slice(4)}` : el.image} alt='bank name' />
                        </div>
                    ))
                }
            </div>
            <div className={s.partnerBox}>
                {allInfo && allInfo?.brand_images.length > 0 &&
                    allInfo?.brand_images.map(el => (
                        <div key={el.id}>
                            <img className={s.img} src={el.image.startsWith('http') ? `https${el.image.slice(4)}` : el.image} alt='bank name' />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Partner;