import React, { FC, useEffect } from 'react';
import BgHome from '../../components/Section/BgHome/BgHome';
import Partner from '../../components/Section/Partner/Partner';
import Advantages from '../../components/Section/Advantages/Advantages';
import PeopleChoice from '../../components/Section/PeopleChoice/PeopleChoice';
import HomeVideo from '../../components/Section/HomeVideo/HomeVideo';
import EquipmentStock from '../../components/Section/EquipmentStock/EquipmentStock';
import Interested from '../../components/Section/Interested/Interested';
import { Helmet } from 'react-helmet-async';
import PartnerSlider from '../../components/Section/PartnerSlider/PartnerSlider';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import Loading from '../../components/Loading/Loading';
import s from './Home.module.scss'
import Coments from '../../components/Section/Coments/Coments';
import Contacts from '../../components/Contacts/Contacts';
import { fetchByPeopleChoice } from '../../store/slice/allInfoSlice';
const Home: FC = () => {
  const { loading, proInStock } = useAppSelector(state => state.allInfo)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchByPeopleChoice())
  }, [dispatch])
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])
  return (
    <div>
      <Helmet>
        <meta property="og:title" content={`Главная | Anto`} />
        <meta name="twitter:title" content={`Главная | Anto`} />
        <link rel="canonical" href={`https://anto.kg/`} />
        <title>
          Главная | Anto
        </title>
      </Helmet>
      <BgHome />
      <Partner />
      <Advantages />
      <PeopleChoice />
      <HomeVideo />
      <EquipmentStock />
      <PartnerSlider />
      <div className={s.partners}>
        <h2>Наши партнеры</h2>
        <Coments />
      </div>
      <Interested />
      <Contacts />
      {loading && <Loading />}
    </div>
  );
};

export default Home;