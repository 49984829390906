import { configureStore } from '@reduxjs/toolkit'
import productSlice from './slice/productSlice'
import categoriesSlice from './slice/categoriesSlice'
import subCategoriesSlice from './slice/subCategoriesSlice'
import FiltersCategoriesSlice from './slice/FiltersCategoriesSlice'
import allInfoSlice from './slice/allInfoSlice'


export const store = configureStore({
  reducer: {
    product: productSlice,
    categoriesTS: categoriesSlice,
    subCategoriesTS: subCategoriesSlice,
    filtersCategoriesTS: FiltersCategoriesSlice,
    productTS: productSlice,
    allInfo: allInfoSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
