import React, { FC, useEffect, useRef } from 'react';
import s from './SubCat.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByAllCategory } from '../../../store/slice/categoriesSlice';
import { fetchByFilterCategory } from '../../../store/slice/FiltersCategoriesSlice';
import { SetURLSearchParams } from 'react-router-dom';

interface SubCatProps {
    setSearchParams: SetURLSearchParams
    query: string | null
    burger: boolean
    setBurger: (e: boolean) => void
}
const SubCat: FC<SubCatProps> = ({ setSearchParams, query, burger, setBurger }) => {
    const dispatch = useAppDispatch()
    const { categories } = useAppSelector(state => state.categoriesTS)


    const filterOnclick = (id: number) => {
        id &&
            dispatch(fetchByFilterCategory(id))
        setSearchParams({ id: `${id}` })
    }

    useEffect(() => {
        document.addEventListener('mousedown', () => setBurger(false))
    }, [burger])

    useEffect(() => {
        dispatch(fetchByAllCategory())
    }, [dispatch])

    return (
        <div className={!burger ? s.subCutOpen : s.subCut}>
            {categories.length > 0 && categories.map(el => (
                <div key={el.id} className={s.aaa}>
                    <h2>{el.name}</h2>
                    <ul>
                        {
                            el.sub_categories.map(el => (
                                <li className={+(query ? query : '') === el.id ? s.active : s.not_active} key={el.id} onClick={() => filterOnclick(el.id)}>{el.name}</li>
                            ))
                        }
                    </ul>
                </div>
            ))
            }
        </div >

    );
};

export default SubCat;