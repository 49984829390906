import React, { FC, useEffect, useState } from 'react';
import s from './Subdirectory.module.scss'
import SubCat from './SubCat/SubCat';
import SubCard from './SubCard/SubCard';
import { Pagination } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { useSearchParams } from 'react-router-dom';
import { fetchByFilterCategory } from '../../store/slice/FiltersCategoriesSlice';
import { GoChevronRight } from "react-icons/go";
import { Helmet } from 'react-helmet-async';
import { fetchByAllSubCategory } from '../../store/slice/subCategoriesSlice';
import Loading from '../../components/Loading/Loading';
import Contacts from '../../components/Contacts/Contacts';

const Subdirectory: FC = () => {
  const { product, loading } = useAppSelector(state => state.filtersCategoriesTS)
  const { subCategories } = useAppSelector(state => state.subCategoriesTS)
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [query, setQuery] = useState(searchParams.get('id'))
  const [burger, setBurger] = useState(false)
  const [subCa, setSubCa] = useState('')
  const [page, setPage] = useState<number>(1)
  const [itemPerPage] = useState<number>(9)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    setSearchParams({ id: `${query}`, p: `${value}` })
  }
  const endIndex = page * itemPerPage
  const startIndex = endIndex - itemPerPage
  const currentProducts = product.slice(startIndex, endIndex)

  useEffect(() => {
    const idParams = searchParams.get('id')
    idParams && setQuery(idParams)

    const paramsPage = searchParams.get('p')
    paramsPage ? setPage(+paramsPage) : setPage(1)

  }, [searchParams, dispatch])

  useEffect(() => {
    dispatch(fetchByAllSubCategory())

    query &&
      dispatch(fetchByFilterCategory(+query))
    query &&
      subCategories.filter(el => el.id === +query && setSubCa(el.name))
  }, [dispatch, query])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [searchParams])


  return (
    <>
      <Helmet>
        <meta property="og:title" content={`Лизинг | Anto`} />
        <meta name="twitter:title" content={`Лизинг | Anto`} />
        <link rel="canonical" href={page ? `https://anto.kg/subdirectory?id=${query}&p=${page}` : `https://anto.kg/subdirectory?id=${query}`} />
        <title>
          {`Список ${product.length > 0 && product[0]?.sub_category.name} | Anto`}
        </title>
      </Helmet>
      <div className={`container ${s.sub}`}>
        <div onClick={e => e.stopPropagation()} className="">
          <button className={s.buttonBurger} onClick={() => setBurger(!burger)}>Категории<span className={s.reactIcons}><GoChevronRight /></span>  </button>
        </div>
        <p className={s.subP}>{product.length > 0 && product[0].sub_category.name}</p>
        <div className={s.subCat}>
          <SubCat setBurger={setBurger} burger={burger} query={query} setSearchParams={setSearchParams} />
        </div>
        <div className={s.sss}>
          <p className={s.pName} >{subCa}</p>
          <div className={s.subCard}>
            {product.length > 0 ?
              currentProducts.map(el => <SubCard key={el.id} {...el} />) :
              <h4 className={s.error}>К сожалению нет в наличии</h4>
            }
          </div>
        </div>
      </div >
      <div className={s.pagination}>
        {
          product.length > itemPerPage &&
          <Pagination
            page={page}
            onChange={handleChange}
            count={Math.ceil(product.length / itemPerPage)}
            variant="outlined" shape="rounded" />
        }
      </div>
      <Contacts />
      {loading && <Loading />}
    </>
  );
};

export default Subdirectory;