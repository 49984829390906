import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CategoriesM } from '../modules'
import { storesApi } from '../../axios'

type InitialState = {
    loading: boolean
    error: null | string | undefined
    categories: CategoriesM[]
}

const initialState: InitialState = {
    error: null,
    loading: false,
    categories: [],
}


export const fetchByAllCategory = createAsyncThunk<CategoriesM[], void, { rejectValue: string }>(
    'categories/fetchByAllCategory', async (_, { rejectWithValue }) => {
        const res = await storesApi.getAllCategories()
        // console.log(res)
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    })


const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {

    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByAllCategory.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByAllCategory.fulfilled, (state, action) => {
            state.categories = action.payload
            state.loading = false
        })

        addCase(fetchByAllCategory.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'Упс что-то пошло не так попробуйте снова!'
            } else {
                state.error = action.payload
            }
        })
    },
})

export default categoriesSlice.reducer
