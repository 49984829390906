import React, { FC, useState } from 'react';
import s from './Interested.module.scss'
import leftImg from '../../../assets/Home/rightimg.png'
import rightImg from '../../../assets/Home/leftimg.png'
import logo from '../../../assets/Home/antologo.png'
import ContactIcons from '../BgHome/ContactIcons/ContactIcons';

const Interested: FC = () => {
    const [contact, setContact] = useState<boolean>(false);

    return (
        <div className={s.Interested}>
            <div className={s.content}>
                <img className={s.logo} src={logo} alt="" />
                <h2 className={s.title}>Заинтересовались?</h2>
                <p className={s.paragraf}>Свяжитесь с нами!
                    Наши специалисты проконсультируют Вас, и подберут лучшую спецтехнику под ваши нужды!
                </p>
                <button onClick={() => setContact(!contact)} className={s.contact}>Свяжись с нами</button>
            </div>
            {contact && <ContactIcons contact={contact} setContact={setContact} />}
            <img className={s.leftImg} src={leftImg} alt="" />
            <img className={s.rightimg} src={rightImg} alt="" />
        </div>
    );
};

export default Interested;