import React, { FC, useEffect } from 'react';
import s from './Leasing.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAddLeasingInfo } from '../../store/slice/allInfoSlice';
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/Loading/Loading';
import Contacts from '../../components/Contacts/Contacts';

const Leasing: FC = () => {
  const { leasing, allInfo, loading } = useAppSelector(state => state.allInfo)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchByAddLeasingInfo())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])
  return (
    <>
      <div className={s.Leasing}>
        <Helmet>
          <meta property="og:title" content={`Лизинг | Anto`} />
          <meta name="twitter:title" content={`Лизинг | Anto`} />
          <link rel="canonical" href={`https://anto.kg/leasing`} />
          <title>
            Лизинг | Anto
          </title>
        </Helmet>
        <div className={s.image_about_company}>
          <img src={leasing?.leasing_page_image.startsWith('http')
            ? `https${leasing?.leasing_page_image.slice(4)}` : leasing?.leasing_page_image}
            alt="Tractors" />
          <div className={s.in_leasingImg}>
            <h2 className={s.description}>{leasing?.leasing_page_banner_text} </h2>
          </div>
        </div>
        <div className={`container ${s.text_in}`}>
          <p>{leasing?.leasing}</p>
        </div>
        <div className={`container ${s.bank_images}`}>
          {allInfo && allInfo?.bank_images.length > 0 &&
            allInfo?.bank_images.map(el => (
              el.bank_link ?
                <a target="_blank" rel="noreferrer" href={el.bank_link} key={el.id} className={s.bank_image}>
                  <img src={el.image.startsWith('http')
                    ? `https${el.image.slice(4)}` : el.image} alt='bank name' />
                </a> :
                <div key={el.id} className={s.bank_image}>
                  <img src={el.image.startsWith('http')
                    ? `https${el.image.slice(4)}` : el.image} alt='bank name' />
                </div>
            ))
          }
        </div>
      </div >
      <Contacts />
      {loading && <Loading />}
    </>
  );
};

export default Leasing;