import React, { FC, useEffect, useState } from 'react';
import './Header.scss'
import logo from '../../assets/Home/antologo.png'
import { Link as ScrollLink } from 'react-scroll';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAllInfo } from '../../store/slice/allInfoSlice';
import { Link, NavLink } from 'react-router-dom';

const Header: FC = () => {
  const [isActiveBurger, setIsActiveBurger] = useState<boolean>(false);
  const { allInfo } = useAppSelector(state => state.allInfo)
  const dispatch = useAppDispatch()

  const navToggle = (): void => {
    setIsActiveBurger(!isActiveBurger);
  };

  useEffect(() => {
    dispatch(fetchByAllInfo())
  }, [dispatch])
  return (
    <>
      <header>
        <div className={`container nav`}>
          <div className='leftContent'>
            <div className='antoicon'>
              <Link to={'/'}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <ul className='menu'>
              <li className='menu_item'><NavLink to={'/catalog'} className={({ isActive }) =>
                isActive ? 'menu_link active_nav' : 'menu_link'
              }>Каталог</NavLink></li>
              <li className='menu_item'><NavLink to={'/about-company'} className={({ isActive }) =>
                isActive ? 'menu_link active_nav' : 'menu_link'
              }>О компании</NavLink></li>
              <li className='menu_item'><NavLink to={'/leasing'} className={({ isActive }) =>
                isActive ? 'menu_link active_nav' : 'menu_link'
              }>Лизинг</NavLink></li>
              <li className='menu_item'>
                <ScrollLink to="footer" smooth={true} duration={500} className='menu_link'>Контакты</ScrollLink></li>
            </ul>
          </div>
          <div className='rightContent'>
            <a className='phoneNumber' href={`tel:${allInfo?.phone_number}`} target="_blank" rel="noreferrer">{allInfo?.phone_number}</a>
          </div>
          <div onClick={navToggle} className={isActiveBurger ? `nav_toogler toogle` : `nav_toogler`}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
      </header >
      <BurgerMenu isActiveBurger={isActiveBurger} setIsActiveBurger={setIsActiveBurger} />
    </>
  );
};

export default Header;