import React, { FC, useEffect } from 'react';
import s from './HomeVideo.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByHomeInfo } from '../../../store/slice/allInfoSlice';

const HomeVideo: FC = () => {
    const { homeinfo } = useAppSelector(state => state.allInfo)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchByHomeInfo())
    }, [dispatch])
    // console.log(homeinfo);


    return (
        <div className={s.HomeVideo}>
            <iframe className={s.iframe} src={homeinfo?.main_page_video.slice(0, 24) + 'embed/' + homeinfo?.main_page_video.slice(32)}
                title='YouTube video player'
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                frameBorder="0"
            ></iframe>
        </div>
    );
};

export default HomeVideo;