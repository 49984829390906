import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByProductId } from '../../store/slice/productSlice';
import { useSearchParams } from 'react-router-dom';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { pathLink } from '../../reused';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './DetailedView.scss'
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/Loading/Loading';
import Contacts from '../../components/Contacts/Contacts';

const DetailedView: FC = () => {
  const dispatch = useAppDispatch()
  const { productId, error, loading } = useAppSelector(state => state.productTS)
  const { allInfo } = useAppSelector(state => state.allInfo)
  const [searchParams] = useSearchParams()
  const [query] = useState(searchParams.get('id'))
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  useEffect(() => {
    query &&
      dispatch(fetchByProductId(+query))
  }, [dispatch, query])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  return (
    <>
      <div className='detailView'>
        <Helmet>
          <meta property="og:title" content={`Каталог | Anto`} />
          <meta name="twitter:title" content={`Каталог | Anto`} />
          <link rel="canonical" href={`https://anto.kg/detailed_view?id=${query}`} />
          <title>
            {`${productId?.sub_category.name}, ${productId?.name} | Anto`}
          </title>
        </Helmet>
        <p className='pName'> {productId?.name}</p>
        <div className='displayOne'>
          <div className='display'>
            <Swiper
              loop={productId && productId?.images.length > 0 ? true : false}
              spaceBetween={10}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[Navigation, Thumbs]}
              className="mySwiper2"
            >
              <SwiperSlide>
                <img src={productId?.image.startsWith('http') ? `https${productId?.image.slice(4)}` : pathLink + productId?.image} alt={productId?.name} />
              </SwiperSlide>
              {
                error ?
                  <span className='error animate__backOutUp animate__animated'>{error}</span>
                  : productId && productId?.images.length > 0 &&
                  productId?.images.map((el, i) => (
                    <SwiperSlide key={i} >
                      <img src={el.image.startsWith('http') ? `https${el.image.slice(4)}` : pathLink + el.image} alt={productId.name} />
                    </SwiperSlide>
                  ))
              }
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={20}
              slidesPerView={window.screen.width < 500 ? 3 : 4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              direction={window.screen.width > 1280 ? 'vertical' : 'horizontal'}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={productId?.image.startsWith('http') ? `https${productId?.image.slice(4)}` : pathLink + productId?.image} alt={productId?.name} />
              </SwiperSlide>
              {
                error ?
                  <span className='error animate__backOutUp animate__animated'>{error}</span>
                  : productId && productId?.images.length > 0 &&
                  productId?.images.map((el, i) => (
                    <SwiperSlide key={i} >
                      <img src={el.image.startsWith('http') ? `https${el.image.slice(4)}` : pathLink + el.image} alt={productId.name} />
                    </SwiperSlide>
                  ))
              }
            </Swiper>
          </div>
          <div className='detail_info'>
            <h2><span>{productId?.description}</span></h2>
            <a href={`https://wa.me/${allInfo?.whatsapp}?text=Здравствуйте,%20хотел%20узнать%20цену%20на%20товар%20anto.kg/detailed_view?id=${productId?.id}`} target="_blank" rel='noopener noreferrer'><span>Узнать стоимость</span></a>
          </div>
        </div>
        <div className='Specifications'>
          <h2>Технические характеристики</h2>
          <div className='twoDiv'>
            <div className='container firstCard'>
              <div className='firstCardBottom'>
                {
                  productId?.features.map((el, i) => (
                    <div key={i}>
                      <h4>{el.name}</h4>
                      <h5>{el.value}</h5>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          {productId?.video && <div className='iframe'>
            <iframe
              className='ifrmB'
              src={productId?.video.slice(0, 24) + 'embed/' + productId?.video.slice(32)}
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              frameBorder="0"
            ></iframe>
          </div>}
        </div>
      </div>
      <Contacts />
      {loading && <Loading />}
    </>
  );
};

export default DetailedView;