import React, { FC, useEffect } from 'react';
import s from './Main.module.scss'
import { Route, Routes } from 'react-router-dom';
import Home from '../../pages/Home/Home';
import AboutCompany from '../../pages/AboutCompany/AboutCompany';
import Catalog from '../../pages/Catalog/Catalog';
import DetailedView from '../../pages/DetailedView/DetailedView';
import Leasing from '../../pages/Leasing/Leasing';
import Subdirectory from '../../pages/Subdirectory/Subdirectory';
import NotFound from '../../pages/NotFound/NotFound';
import { useAppDispatch } from '../../store/hooks/hooks';
import { fetchByAllInfo, fetchByComments } from '../../store/slice/allInfoSlice';

const Main: FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchByAllInfo())
    dispatch(fetchByComments())
  }, [dispatch])

  return (
    <main className={s.main}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-company' element={<AboutCompany />} />
        <Route path='/catalog' element={<Catalog />} />
        <Route path='/detailed_view' element={<DetailedView />} />
        <Route path='/leasing' element={<Leasing />} />
        <Route path='/subdirectory' element={<Subdirectory />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </main>
  );
};

export default Main;