import React, { FC, useEffect } from 'react';
import s from './Footer.module.scss'
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/Home/futerlogo.png'
import ins from '../../assets/Home/insicon.png'
import whs from '../../assets/Home/wsicon.png'
import tel from '../../assets/Home/telicon.png'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAllInfo } from '../../store/slice/allInfoSlice';

const Footer: FC = () => {
  const { allInfo } = useAppSelector(state => state.allInfo)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchByAllInfo())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <footer id='footer'>
      <div className={`container ${s.footerNav}`}>
        <div className={s.topBox}>
          <div className={s.leftItem}>
            <div className='antoicon'>
              <Link to={'/'}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className={s.topItem}>
            <ul className={s.menu}>
              <li className={s.menu_item}><NavLink to={'/catalog'} className={s.menu_link}>Каталог</NavLink></li>
              <li className={s.menu_item}><NavLink to={'/about-company'} className={s.menu_link}>О компании</NavLink></li>
              <li className={s.menu_item}><NavLink to={'/leasing'} className={s.menu_link}>Лизинг</NavLink></li>
              <li className={s.menu_item}><NavLink to={'#'} className={s.menu_link}>Контакты</NavLink></li>
            </ul>
          </div>
          <div className={s.rightItem}>
            {allInfo?.whatsapp &&
              <a target="_blank" rel="noreferrer" href={"https://wa.me/" + allInfo?.whatsapp}>
                <img className={s.img} src={whs} alt="whs" />
              </a>
            }
            {
              allInfo?.telegram &&
              <a target="_blank" rel="noreferrer" href={allInfo?.telegram.startsWith('https') ? allInfo?.telegram : `https://t.me/${allInfo?.telegram.startsWith('+') ? allInfo?.telegram : allInfo?.telegram.startsWith('@') ? `https://t.me/${allInfo?.telegram.slice(1)}` : (allInfo && +allInfo?.telegram) ? '+' + allInfo?.telegram : allInfo?.telegram}`}>
                <img className={s.img} src={tel} alt="tel" />
              </a>
            }
            {
              allInfo?.instagram &&
              <a target="_blank" rel="noreferrer" href={'https://www.instagram.com/' + allInfo?.instagram}>
                <img className={s.img} src={ins} alt="ins" />
              </a>
            }
          </div>
        </div>
        <div className={s.bottomBox}>
          <h2 className={s.paragraf}>Дизайн Студия “ITMAG”  © 2024 Все права защищены.</h2>
        </div>
      </div>
    </footer>
  );
};

export default Footer;