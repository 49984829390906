import React, { FC } from 'react';
import s from './SubCard.module.scss'
import { Link } from 'react-router-dom';
import { ProductsM } from '../../../store/modules';
import { pathLink } from '../../../reused';
import { useAppDispatch } from '../../../store/hooks/hooks';
import { fetchByProductId } from '../../../store/slice/productSlice';
const SubCard: FC<ProductsM> = ({ id, image, in_stock, name }) => {
    const dispatch = useAppDispatch()

    const getDetailview = () => {
        id &&
            dispatch(fetchByProductId(id))
    }

    return (
        <div className={s.card}>
            <div className={s.cardImg}>
                <img src={image.startsWith('http') ? image : pathLink + image} alt={name} />
                {in_stock && <button>
                    <span>В наличии</span>
                </button>}
            </div>
            <p>{name}</p>
            <Link to={`/detailed_view?id=${id}`} >
                <div className={s.center}>
                    <button onClick={getDetailview}>
                        <span>Подробнее</span>
                    </button>
                </div>
            </Link>
        </div>

    );
};

export default SubCard;