import React, { FC, useEffect } from 'react';
import s from './ContactModal.module.scss'
import whs from '../../../assets/Home/wsicon.png'
import ins from '../../../assets/Home/insicon.png'
import tel from '../../../assets/Home/telicon.png'
import { fetchByAllInfo } from '../../../store/slice/allInfoSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';

const ContactModal: FC = () => {
    const { allInfo } = useAppSelector(state => state.allInfo)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchByAllInfo())
    }, [dispatch])
    return (
        <div className={s.icons}>
            {allInfo?.whatsapp &&
                <a target="_blank" rel="noreferrer" href={"https://wa.me/" + allInfo?.whatsapp}>
                    <div className={s.whatssap}>
                        <img src={whs} alt="" />
                    </div>
                </a>
            }
            {allInfo?.telegram &&
                <a target="_blank" rel="noreferrer" href={allInfo?.telegram.startsWith('https') ? allInfo?.telegram : `https://t.me/${allInfo?.telegram.startsWith('+') ? allInfo?.telegram : allInfo?.telegram.startsWith('@') ? `https://t.me/${allInfo?.telegram.slice(1)}` : (allInfo && +allInfo?.telegram) ? '+' + allInfo?.telegram : allInfo?.telegram}`}>
                    <div className={s.telegramm}>
                        <img src={tel} alt="" />
                    </div>
                </a>
            }
            {allInfo?.instagram &&
                <a target="_blank" rel="noreferrer" href={'https://www.instagram.com/' + allInfo?.instagram}>
                    <div className={s.insta}>
                        <img src={ins} alt="" />
                    </div>
                </a>
            }
        </div>
    );
};

export default ContactModal;