import React, { FC, useEffect, useState } from 'react';
import s from './BgHome.module.scss'
import { Link } from 'react-router-dom';
import ContactIcons from './ContactIcons/ContactIcons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByHomeInfo } from '../../../store/slice/allInfoSlice';

const BgHome: FC = () => {
    const [contact, setContact] = useState<boolean>(false);
    const { homeinfo } = useAppSelector(state => state.allInfo)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByHomeInfo())
    }, [dispatch])

    return (
        <>
            <div style={{
                backgroundImage: ` url(${homeinfo?.main_image.startsWith('http')
                    ? `https${homeinfo?.main_image.slice(4)}` : homeinfo?.main_image})`
            }}
                className={s.bghome}>
                <div className={`container ${s.contentBox}`}>
                    <div className={s.topContent}>
                        <h2 className={s.title}>{homeinfo?.main_image_text}</h2>
                    </div>
                    <div className={s.centerContent}>
                        <p className={s.paragraf}>{homeinfo?.main_image_sub_text}</p>
                    </div>
                    <div className={s.bottomContent}>
                        <Link to={'/catalog'}>
                            <button className={s.catalog}>Каталог</button>
                        </Link>
                        <button onClick={() => setContact(!contact)} className={s.contact}>Связаться</button>
                    </div>
                </div>

            </div>
            {contact && <ContactIcons contact={contact} setContact={setContact} />}
        </>
    );
};

export default BgHome;